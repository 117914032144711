import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../store";
import { OptimalRange, updateOptimalRange } from "../store/fields/fieldsSlice";
import EditablePill from "./EditablePill";

interface OptimalRangeFormProps {
  optimalRange: OptimalRange;
  type: string;
}

const OptimalRangeForm: FC<OptimalRangeFormProps> = ({
  optimalRange,
  type,
}) => {
  const dispatch = useAppDispatch();

  const [rangeEditVisible, setRangeEditVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updateOptimalRange(
        optimalRange.rangeId,
        event.target.range_min.value,
        event.target.range_max.value,
        type
      )
    );
    setRangeEditVisible(false);
  };

  const { t } = useTranslation("translation");

  return (
    <>
      <div className="flex items-center ml-auto text-sm h-4">
        <span className="mx-1">{t("fieldDetailsPage.optimalRange")}: </span>
        {rangeEditVisible ? (
          <form onSubmit={handleSubmit} className="flex items-center">
            <input
              type="text"
              name="range_min"
              className="block mx-1 w-12 appearance-none rounded-md border border-gray-300 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm text-center"
              defaultValue={Number(optimalRange?.minValue) ?? ""}
            />
            -
            <input
              type="text"
              name="range_max"
              className="block mx-1 w-12 appearance-none rounded-md border border-gray-300 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm text-center"
              defaultValue={Number(optimalRange?.maxValue) ?? ""}
            />
            {optimalRange?.unit}
            <button
              type="submit"
              className="rounded-md mx-1 border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
            >
              {t("fieldDetailsPage.saveOptimalRange")}
            </button>
          </form>
        ) : (
          <div onClick={() => setRangeEditVisible(true)}>
            <EditablePill>
              {Number(optimalRange?.minValue ?? "?")} -{" "}
              {Number(optimalRange?.maxValue ?? "?")} {optimalRange?.unit}
            </EditablePill>
          </div>
        )}
      </div>
    </>
  );
};

export default OptimalRangeForm;
