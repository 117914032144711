import React, { useRef, FC, ReactNode, Fragment, useEffect } from "react";
import { LatLngExpression, LeafletEventHandlerFn } from "leaflet";
import { useTranslation } from "react-i18next";
import * as L from "leaflet";
import { Menu, Transition } from "@headlessui/react";
import { customizeToolbarSettings } from "../utils/mapToolbarSettings";

import {
  MapContainer,
  Marker,
  TileLayer,
  FeatureGroup,
  ImageOverlay,
  useMap,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import { dateFormatter } from "../utils/dateFormatter";
import MapSearch from "../components/MapSearch";
import { noop } from "../utils/noop";
import { scripts } from "../utils/scriptsData";
import { useSatalliteImagery } from "../hooks/useSatelliteImagery";

import { Language, drawLocales } from "leaflet-draw-locales";

customizeToolbarSettings();

const MapControl = ({ language }) => {
  const map = useMap();
  const { t } = useTranslation("translation");

  useEffect(() => {
    drawLocales(language as Language);
  }, [language, map]);

  return null;
};

interface MapProps {
  center: LatLngExpression;
  zoom: number;
  searchOn?: boolean;
  infoOn?: boolean;
  editOn?: boolean;
  onResultSelect?: LeafletEventHandlerFn;
  zoomControl?: boolean;
  scrollWheelZoom?: boolean;
  dragging?: boolean;
  height?: string;
  width?: string;
  showMarker?: boolean;
  keyboard?: boolean;
  children?: ReactNode;
}

const Map: FC<MapProps> = ({
  center,
  zoom,
  searchOn = false,
  onResultSelect = noop(),
  zoomControl = true,
  scrollWheelZoom = true,
  dragging = false,
  height = "42rem",
  width = "100%",
  infoOn = true,
  editOn = true,
  keyboard = false,
  children,
}) => {
  const {
    selectedScriptName,
    coordinatesLatLngBounds,
    dates,
    infoActive,
    imageUrl,
    loading,
    errorMessage,
    setSelectedScriptName,
    handleAreaCreated,
    clearImageryData,
  } = useSatalliteImagery();

  const drawnArea = useRef<L.FeatureGroup>(null);
  const satelliteOverlay = useRef<L.ImageOverlay>(null);

  const { t, i18n } = useTranslation("translation");

  const clearMapOverlay = () => {
    const drawnAreaCurrent = drawnArea.current;
    drawnAreaCurrent && drawnAreaCurrent.clearLayers();

    const satelliteOverlayCurrent = satelliteOverlay.current;
    satelliteOverlayCurrent && satelliteOverlayCurrent.remove();

    clearImageryData();
  };

  return (
    <div>
      {infoOn && (
        <div className="flex justify-between items-baseline py-4">
          {!infoActive && !loading && (
            <div className="info-container flex items-center my-0 mx-auto min-h-[60px]">
              <img
                className="pr-1 -mt-2"
                src={`${require("../assets/icons/flag.svg")}`}
                alt="flag"
              />
              <div className="flex flex-col">
                <p className="test-sm leading-3">{t("mapDetails.markArea")}</p>
                <p className="text-xs text-gray-500 leading-6">
                  {t("mapDetails.usePanel")}
                </p>
              </div>
            </div>
          )}
          {!infoActive && loading && (
            <div className="flex items-center my-0 mx-auto min-h-[60px]">
              <div
                className="inline-block h-8 w-8 animate-spin-slow rounded-full border-4 border-solid border-transparent border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] my-0 mx-auto"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  {t("mapDetails.loading")}
                </span>
                <img
                  src={`${require("../assets/icons/spinner.svg")}`}
                  alt="flag"
                />
              </div>
              <p className="test-sm">{t("mapDetails.downloadingData")}</p>
            </div>
          )}
          {infoActive && !errorMessage && (
            <div className="flex flex-col items-end ml-auto mr-4 min-h-[60px]">
              <Menu
                as="div"
                className="relative inline-block text-left z-50 min-w-[150px]"
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-amber-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-amber-100 hover:bg-amber-200">
                        <div>{t(`scriptNames.${selectedScriptName}`)}</div>

                        <svg
                          className={`-mr-1 h-5 w-5 text-gray-900 ${
                            open ? "rotate-180" : ""
                          }`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-full  origin-top-right rounded-md bg-amber-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {Object.keys(scripts).map((scriptName) => (
                            <Menu.Item key={scriptName}>
                              <button
                                className="block w-full px-4 py-2 text-left text-sm hover:bg-amber-200 text-gray-900"
                                onClick={() =>
                                  setSelectedScriptName(scriptName)
                                }
                              >
                                {t(`scriptNames.${scriptName}`)}
                              </button>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>

              <p className="text-sm">
                {t("mapDetails.imageDate")}{" "}
                {dates && (
                  <span className="text-gray-500">{dateFormatter(dates)}</span>
                )}
              </p>
            </div>
          )}
          {infoActive && errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}
        </div>
      )}

      <MapContainer
        key={i18n.language}
        className="z-10"
        center={center}
        zoom={zoom}
        zoomControl={zoomControl}
        scrollWheelZoom={scrollWheelZoom}
        dragging={dragging}
        keyboard={keyboard}
        style={{ height: height, width: width }}
      >
        <MapControl language={i18n.language} />
        {searchOn && <MapSearch onResultSelect={onResultSelect} />}
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          maxZoom={19}
          minZoom={15}
        />
        <>{children}</>
        {editOn && (
          <FeatureGroup ref={drawnArea}>
            <EditControl
              position="topright"
              onCreated={handleAreaCreated}
              onDrawStart={clearMapOverlay}
              onDeleteStart={clearMapOverlay}
              draw={{
                rectangle: true,
                polyline: false,
                circle: false,
                marker: false,
                polygon: true,
                circlemarker: false,
              }}
              edit={{
                edit: false,
                // hiding remove toolbar options from remove button in leaflet.scss
                remove: true,
              }}
            />
          </FeatureGroup>
        )}
        {imageUrl && (
          <ImageOverlay
            ref={satelliteOverlay}
            url={typeof imageUrl === "string" ? imageUrl : ""}
            bounds={coordinatesLatLngBounds}
            opacity={0.8}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
