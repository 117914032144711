import { ApexOptions } from "apexcharts";
import moment from "moment";
import React, { FC, useRef } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { OptimalRange } from "../store/fields/fieldsSlice";
import { Measurement, Sonda } from "../store/sonda/sondaSlice";
import { customTooltip } from "./apexcharts/customTooltip";

type DailyMeasurement = {
  x: Date;
  y: number;
};

type Series = {
  name: string;
  data: DailyMeasurement[];
  unit: string;
};

interface MeasurementChartProps {
  type: string;
  sondas: Sonda[];
  optimalRange: OptimalRange;
}

const MeasurementChart: FC<MeasurementChartProps> = ({
                                                       type,
                                                       sondas,
                                                       optimalRange,
                                                     }) => {
  const data: Series[] = prepareMeasurementData(type, sondas, optimalRange);
  const chartRef = useRef<Chart | null>(null);

  const allMeasurements = sondas.flatMap((sonda) => sonda.measurements ?? []);

  const sortedMeasurements = allMeasurements.sort(
      (a, b) => new Date(a.date_time).getTime() - new Date(b.date_time).getTime()
  );

  let initialMinDate: number | undefined;
  let initialMaxDate: number | undefined;

  if (sortedMeasurements.length > 0) {
    initialMinDate = new Date(sortedMeasurements[0].date_time).getTime(); // Earliest date
    initialMaxDate = new Date(
        sortedMeasurements[sortedMeasurements.length - 1].date_time
    ).getTime();
  }

  const yValues = data.flatMap((series) => series.data.map((point) => point.y));
  const initialMinY = Math.min(...yValues);
  const initialMaxY = Math.max(...yValues);

  const { t } = useTranslation("translation");

  let options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      animations: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: {
            headerCategory: "date",
            headerValue: "value",
            dateFormatter(timestamp) {
              return timestamp === undefined
                  ? new Date("1970-01-01").toISOString()
                  : new Date(timestamp).toISOString();
            },
          },
        },
      },
      offsetX: -15,
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          const visibleDataPoints = data.flatMap((series) =>
              series.data.filter(
                  (point) =>
                      point.x.getTime() >= xaxis.min &&
                      point.x.getTime() <= xaxis.max
              )
          );

          if (visibleDataPoints.length > 0) {
            const visibleYValues = visibleDataPoints.map((point) => point.y);
            const minY = Math.min(...visibleYValues);
            const maxY = Math.max(...visibleYValues);

            if (chartRef.current) {
              chartRef.current.chart.updateOptions({
                yaxis: {
                  min: Math.min(minY, parseInt(optimalRange.minValue)),
                  max: Math.max(maxY, parseInt(optimalRange.maxValue)),
                },
              });
            }
          }
        },
        beforeResetZoom: function (chartContext) {
          chartContext.updateOptions(
              {
                xaxis: {
                  min: initialMinDate,
                  max: initialMaxDate,
                },
                yaxis: {
                  min: initialMinY,
                  max: initialMaxY,
                },
              },
              false,
              true
          );
          return {};
        },
        beforeZoom: (e, { xaxis }) => {
          const earliestDataDate = initialMinDate;
          const latestDataDate = initialMaxDate;

          if (xaxis.min < earliestDataDate) {
            xaxis.min = earliestDataDate;
          }
          if (xaxis.max > latestDataDate) {
            xaxis.max = latestDataDate;
          }

          return {
            xaxis: {
              min: xaxis.min,
              max: xaxis.max,
            },
          };
        },
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      type: "datetime",
      min: initialMinDate,
      max: initialMaxDate,
    },
    yaxis: {
      min: Math.min(initialMinY, parseInt(optimalRange.minValue)),
      max: Math.max(initialMaxY, parseInt(optimalRange.maxValue)),
    },
    legend: {
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      offsetX: 0,
      offsetY: 10,
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "round",
      width: 2,
    },
    fill: {
      opacity: 0.65,
    },
    markers: {
      size: 2.5,
      strokeColors: "#fff",
      strokeWidth: 0,
      strokeOpacity: 0,
      strokeDashArray: 0,
      fillOpacity: 0.7,
      shape: "circle",
      radius: 2,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
    },
    annotations: {
      yaxis: [],
    },
    tooltip: {
      followCursor: true,
      custom: customTooltip,
      x: {
        formatter: function (value) {
          return moment(value).format("DD MMM HH:mm");
        },
      },
    },
  };

  if (optimalRange) {
    options.annotations.yaxis.push({
      y: optimalRange.maxValue,
      y2: optimalRange.minValue,
      borderColor: "#FEB019",
      fillColor: "rgb(0, 227, 150)",
      opacity: 0.1,
      label: {
        style: {
          fontSize: "12px",
          color: "#333",
          background: "rgb(243 244 246)",
        },
        text: t("fieldDetailsPage.optimalRange"),
        offsetY: 17,
      },
    });
  }

  return <Chart ref={chartRef} options={options} series={data} />;
};

const prepareMeasurementData = (
    type: string,
    sondas: Sonda[],
    optimalRange: OptimalRange
): Series[] => {
  return sondas.map((sonda: Sonda, index: number) => ({
    name: `Sonda ${index + 1}`,
    data: (sonda?.measurements ?? [])
        .filter((measurement: Measurement) => measurement[type] !== null)
        .map((measurement: Measurement) => {
          const measurementDate = moment(measurement.date_time).toDate();
          return {
            x: measurementDate,
            y: measurement[type],
          };
        }),
    unit: optimalRange.unit,
  }));
};

export default MeasurementChart;
