import React, { FC } from "react";

interface PillProps {
  children: React.ReactNode;
}

const Pill: FC<PillProps> = ({ children }) => (
  <div className="rounded-full bg-gray-100 px-2 py-1 text-center leading-6 inline-block">
    {children}
  </div>
);

export default Pill;
