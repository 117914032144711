import React, { FC } from "react";
import Pill from "./Pill";

interface MeasurementProps {
  title: string;
  minValue: number | string;
  maxValue: number | string;
  datetime: Date | null;
  unit?: string;
}

const Measurement: FC<MeasurementProps> = ({
  title,
  minValue,
  maxValue,
  unit = "",
}) => {
  const value: string =
    minValue === maxValue ? `${minValue}` : `${minValue}-${maxValue}`;
  return (
    <div className="text-sm">
      <div className="font-bold mb-2 inline-block">{title}</div>
      <br />
      <Pill>{`${value} ${unit}`}</Pill>
    </div>
  );
};

export default Measurement;
