import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ActionPanel from "../components/ActionPanel";

const StartMonitoring: FC = () => {
  const { t } = useTranslation("translation");

  return (
    <ActionPanel size="small">
      <h1 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        {t("startMonitoringPage.startMonitoringTitle")}
      </h1>
      <div className="my-8 max-w-xl text-sm text-gray-500 text-center">
        <p>{t("startMonitoringPage.startMonitoringText")}</p>
      </div>
      <div className="mt-5 mb-4 text-center">
        <Link
          to="/add-field"
          className="flex w-full sm:w-6/12 mx-auto justify-center rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
        >
          {t("startMonitoringPage.startMonitoringButton")}
        </Link>
      </div>
    </ActionPanel>
  );
};

export default StartMonitoring;
