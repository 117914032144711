import "./i18n";

import * as React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("app");
  const root = createRoot(container);
  root.render(<App />);
});
