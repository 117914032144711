import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState, initialAuthState } from "./state";

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    doSignIn: (state) => {
      state.isAuthenticated = true;
      state.error = undefined;
    },
    doSignOut: (state) => {
      state.isAuthenticated = false;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<Partial<AuthState>>) => {
      state.error = action.payload.error;
    },
  },
});

export const { doSignIn, doSignOut, setError } = authSlice.actions;

export const authReducer = authSlice.reducer;
