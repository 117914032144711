import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/useAuth";
import ActionPanel from "../components/ActionPanel";
import { useAppDispatch } from "../store";
import {
  NotificationType,
  triggerNotification,
} from "../store/notification/notificationSlice";

const LogIn: FC = () => {
  const dispatch = useAppDispatch();
  const { error, signin } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { t } = useTranslation("translation");

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await signin(user.email, user.password);
    navigate("/");
  };

  useEffect(() => {
    if (error) {
      dispatch(
        triggerNotification({
          messages: ["common.errorMessage"],
          type: NotificationType.ERROR,
        })
      );
    }
  }, [error]);

  return (
    <ActionPanel size="small">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900">
            {t("logInPage.logIn")}
          </h1>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-500"
          >
            {t("logInPage.email")}
          </label>
          <div className="mt-1">
            <input
              id="email"
              type="email"
              name="email"
              onChange={(e) => handleChange(e)}
              autoComplete="email"
              required
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-500"
          >
            {t("logInPage.password")}
          </label>
          <div className="mt-1">
            <input
              id="password"
              type="password"
              name="password"
              onChange={(e) => handleChange(e)}
              autoComplete="current-password"
              required
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm"
            />
          </div>
        </div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
        >
          {t("logInPage.logIn")}
        </button>
      </form>
    </ActionPanel>
  );
};

export default LogIn;
