import React, { FC } from "react";
import classNames from "classnames";

interface ActionPanelProps {
  children: React.ReactNode;
  size?: string;
}

const ActionPanel: FC<ActionPanelProps> = ({ children, size = "large" }) => {
  const containerClasses = classNames("sm:mx-auto", "sm:w-full", {
    "mt-24 sm:max-w-md": size == "small",
    "mt-8 mx-auto max-w-6xl": size == "large",
  });

  return (
    <div className={containerClasses}>
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {children}
      </div>
    </div>
  );
};

export default ActionPanel;
