import { createContext } from "react";

import { AuthState, initialAuthState } from "./state";

const stub = (): never => {
  throw new Error("Wrap your components in <AuthProvider>");
};

const initialContext = {
  ...initialAuthState,
  signin: stub,
  signout: stub,
};

export interface AuthContextInterface extends AuthState {
  signin: (email: string, password: string) => void;
  signout: () => void;
}

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
