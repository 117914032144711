import {LatLngExpression} from "leaflet";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Marker, Popup} from "react-leaflet";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import Map from "../components/Map";
import MeasurementChart from "../components/MeasurementChart";
import MeasurementList from "../components/MeasurementList";
import MeasurementTypeTabs from "../components/MeasurementTypeTabs";
import {useAppDispatch} from "../store";
import {
    fetchFieldDetails,
    Field,
    selectField,
} from "../store/fields/fieldsSlice";
import {Measurement, Sonda} from "../store/sonda/sondaSlice";
import {OptimalRange} from "../store/optimal_range/optimalRangeSlice";
import classNames from "classnames";

const FieldDetails: FC = () => {
    const dispatch = useAppDispatch();
    const {state} = useLocation();
    const field: Field | undefined = useSelector(selectField);
    const {t} = useTranslation("translation");

    useEffect(() => {
        dispatch(fetchFieldDetails(state.field.fieldId));
    }, []);

    const mapCenter: LatLngExpression = {
        lat: state.field.lat,
        lng: state.field.lng,
    };

    const sondas: Sonda[] | undefined = field?.sondas;

    const optimalRanges: OptimalRange[] | undefined = field?.optimalRanges;

    const lastValidMeasurement = (sonda: Sonda): Measurement | undefined =>
        sonda.latest_valid;

    const tabs = [
        {id: "moisture", name: t("common.moisture"), unit: "%"},
        {id: "temperature", name: t("common.temperature"), unit: "°C"},
        {id: "nitrate", name: t("common.nitrate"), unit: "ppm"},
        // temporarily hiding phosphate and ph
        // { id: "phosphate", name: t("common.phosphate"), unit: "" },
        // { id: "ph", name: t("common.ph"), unit: "" },
        {id: "ec", name: t("common.ec"), unit: "dS/m"},
    ];

    const [currentMeasurementType, setCurrentMeasurementType] = useState(
        tabs[0].id
    );

    const handleTabChange = (tabId: string): void => {
        setCurrentMeasurementType(tabId);
    };

    const renderLastMeasurements = (
        sondas: Sonda[] | undefined
    ): React.ReactNode => {
        if (sondas !== undefined) {
            return tabs.map((tab, index) => {
                return (
                    tab.id === currentMeasurementType && (
                        <MeasurementList
                            type={tab.id}
                            sondas={sondas}
                            title={tab.name}
                            unit={tab.unit}
                            key={index}
                            optimalRange={optimalRanges.find(
                                (range) => range.measurementType === currentMeasurementType
                            )}
                        />
                    )
                );
            });
        }
    };

    const renderSondaMarkers = (sondas: Sonda[] | undefined): React.ReactNode => {
        if (sondas !== undefined) {
            return sondas.map((sonda: Sonda) => {
                const lastMeasurementLat = lastValidMeasurement(sonda).latitude[0];
                const lastMeasurementLng = lastValidMeasurement(sonda).longitude[0];

                // Hotfix for NaN  sonda coordinates
                // Check for NaN and set to 0 if true
                const latitude =
                    isNaN(lastMeasurementLat) || !lastMeasurementLat
                        ? 0
                        : lastMeasurementLat;
                const longitude =
                    isNaN(lastMeasurementLng) || !lastMeasurementLng
                        ? 0
                        : lastMeasurementLng;

                const sondaPosition: LatLngExpression = {
                    lat: latitude,
                    lng: longitude,
                };
                return (
                    <Marker key={sonda.sondaCode} position={sondaPosition}>
                        <Popup>{`${t("common.sonda")} ${sonda.sondaCode}`}</Popup>
                    </Marker>
                );
            });
        }
    };

    const renderChart = (sondas: Sonda[] | undefined): React.ReactNode => {
        if (sondas !== undefined) {
            return tabs.map((tab, index) => {
                return (
                    tab.id === currentMeasurementType && (
                        <MeasurementChart
                            type={currentMeasurementType}
                            sondas={sondas}
                            key={index}
                            optimalRange={optimalRanges.find(
                                (range) => range.measurementType === currentMeasurementType
                            )}
                        />
                    )
                );
            });
        }
    };

    const hasSondas: boolean = sondas?.length !== 0;

    return (
        <>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 px-4">
                <Link
                    to="/"
                    className="block mt-4 py-2 text-green-800 focus:outline-none focus:text-green-700"
                >
                    ← {t("fieldDetailsPage.backLink")}
                </Link>
                <div className="sm:columns-2 lg:columns-3 pt-4 pb-6">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 inline-block">
                        {state.field.name}
                    </h1>
                    <div className="text-center">
                        <MeasurementTypeTabs
                            tabs={tabs}
                            handleTabChange={handleTabChange}
                            activeTab={currentMeasurementType}
                            hasMeasurementTypes={hasSondas}
                        />
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-7xl px-4 lg:pl-8 lg:flex lg:flex-row bg-white shadow">
                <div className="mx-auto lg:w-[900px]">{renderChart(sondas)}</div>
            </div>
            <div className="mx-auto max-w-7xl sm:px-6 lg:pl-8 lg:flex lg:flex-row bg-white shadow ">
                <div className="p-4 sm:pl-0 sm:pr-4 basis-1/2 flex flex-col relative">
                    {!hasSondas && (
                        <div
                            className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/4 flex flex-col items-center z-10">
                            <p className="text-lg my-4">{t("fieldListPage.noSonda")}</p>
                            <Link
                                to="/new-sonda"
                                state={{field: field}}
                                className="inline-block mt-3 rounded-md bg-gray-100 py-2 px-8 text-sm text-green-800 font-bold shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 border border-gray-200"
                            >
                                {t("fieldListPage.addSondaButton")}
                            </Link>
                        </div>
                    )}
                    <div className={classNames(hasSondas ? "" : "blur")}>
                        <div className="my-6 sm:mb-0">{renderLastMeasurements(sondas)}</div>
                    </div>
                </div>
                <div className="field-details-map basis-1/2">
                    <Map
                        center={mapCenter}
                        scrollWheelZoom={true} // TODO change to false when sonda positions in relation to their fields make sense
                        zoom={15}
                        zoomControl={true} // TODO change to false when sonda positions in relation to their fields make sense
                        dragging={true} // TODO change to false when sonda positions in relation to their fields make sense
                        height={"565px"}
                        width={"100%"}
                    >
                        {renderSondaMarkers(sondas)}
                    </Map>
                </div>
            </div>
        </>
    );
};

export default FieldDetails;
