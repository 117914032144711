import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useAuth } from "./auth/useAuth";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Notification from "./components/Notification";
import AddField from "./pages/AddField";
import AddSonda from "./pages/AddSonda";
import Dashboard from "./pages/Dashboard";
import FieldDetails from "./pages/FieldDetails";
import LogIn from "./pages/LogIn";
import NewSonda from "./pages/NewSonda";

const AppRouter = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <NavBar />
      <Notification />
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/add-field" element={<AddField />} />
          <Route path="/new-sonda" element={<NewSonda />} />
          <Route path="/add-sonda" element={<AddSonda />} />
          <Route path="/field-details" element={<FieldDetails />} />
        </Routes>
      ) : (
        <LogIn />
      )}
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
