import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../";

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
}

export interface Notification {
  messages?: string[];
  type?: NotificationType;
}

const initialNotificationState: Notification = {
  messages: undefined,
  type: undefined,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialNotificationState,
  reducers: {
    triggerNotification: (state, action: PayloadAction<Notification>) => {
      state.messages = action.payload.messages;
      state.type = action.payload.type;
    },
    clearNotification: (state) => {
      state.messages = undefined;
      state.type = undefined;
    },
  },
});

export const notificationReducer = notificationSlice.reducer;

export const { triggerNotification, clearNotification } =
  notificationSlice.actions;

export const selectNotification = (state: RootState): Notification =>
  state.notification;
