import moment from "moment";
import React, { FC } from "react";

import { OptimalRange } from "../store/fields/fieldsSlice";
import { Sonda } from "../store/sonda/sondaSlice";
import MeasurementListItem from "./MeasurementListItem";
import OptimalRangeForm from "./OptimalRangeForm";
import Table from "./Table";

interface MeasurementListProps {
  sondas: Sonda[];
  title: string;
  type: string;
  unit?: string;
  optimalRange: OptimalRange;
}

const getFormattedLastValidDate = (sonda: Sonda, type: string) => {
  const datetime = sonda.latest_valid?.[type]?.[1];
  return datetime ? moment.utc(datetime).format("DD/MM/YYYY HH:mm") : "";
};

const getLatestValidValue = (sonda: Sonda, type: string) => {
  return sonda.latest_valid?.[type]?.[0];
};

const MeasurementList: FC<MeasurementListProps> = ({
  sondas,
  title,
  type,
  unit = "",
  optimalRange,
}) => {
  return (
    <>
      <div className="flex items-center">
        <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
        <OptimalRangeForm optimalRange={optimalRange} type={type} />
      </div>
      <Table>
        {sondas.map((sonda, index) => {
          return (
            <MeasurementListItem
              sonda={sonda}
              key={index}
              value={getLatestValidValue(sonda, type)}
              index={index}
              unit={unit}
              formattedDate={getFormattedLastValidDate(sonda, type)}
            />
          );
        })}
      </Table>
    </>
  );
};

export default MeasurementList;
