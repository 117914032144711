import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  clearNotification,
  Notification,
  NotificationType,
  selectNotification,
} from "../store/notification/notificationSlice";

const Notification: FC = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const notification: Notification = useSelector(selectNotification);
  const { t } = useTranslation("translation");

  const onCloseNotification = () => {
    dispatch(clearNotification());
  };

  const notificationStyle: string =
    notification.type === NotificationType.SUCCESS
      ? "bg-green-800 py-1"
      : "bg-red-800 py-3";
  const notificationClassName: string = `flex items-center text-white text-sm px-1 rounded-md m-5 relative ${notificationStyle}`;

  return notification.type ? (
    <div ref={ref} className="sm:mx-auto sm:w-full mt-4 mx-auto max-w-6xl">
      <div className={notificationClassName} role="alert">
        {notification.type === NotificationType.SUCCESS && (
          <img
            src={`${require("../assets/icons/tick-box.svg")}`}
            alt={t("imgAlt.tickBox") as string}
          />
        )}

        <p className="ml-3 mr-8">
          {notification.messages &&
            notification.messages.map((messageKey, index) => (
              <span key={index}>{t(messageKey)} </span>
            ))}
        </p>

        <button className="absolute right-2.5" onClick={onCloseNotification}>
          {notification.type === NotificationType.SUCCESS ? (
            <img
              src={`${require("../assets/icons/close-green.svg")}`}
              alt={t("imgAlt.close") as string}
            />
          ) : (
            <img
              src={`${require("../assets/icons/close-red.svg")}`}
              alt={t("imgAlt.close") as string}
            />
          )}
        </button>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default Notification;
