import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

interface AlertModalFieldProps {
  deleteFieldOnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AlertModalField: FC<AlertModalFieldProps> = ({ deleteFieldOnClick }) => {
  const { t } = useTranslation("translation");
  return (
    <Popup
      trigger={
        <button type="button">
          <div className="flex items-center px-2 py-1 ">
            <span className="text-sm mr-2">
              {t("fieldListPage.deleteField")}
            </span>
            <img
              src={`${require("../assets/icons/rubbish-bin.svg")}`}
              alt="Delete field button"
            />
          </div>
        </button>
      }
      modal
      nested
    >
      {
        // @ts-ignore
        //Ignoring ts because of missing types in library
        //https://github.com/yjose/reactjs-popup/issues/340
        (close) => {
          return (
            <div className="bg-white border border-transparent rounded-lg shadow-lg px-7 py-7 w-[420px]">
              <button
                className="cursor-pointer absolute block leading-5 text-2xl border px-[5px] py-0.5 rounded-[18px] border-solid border-[#cfcece] right-0.5 top-0.5 mt-2 mr-2"
                onClick={close}
              >
                &times;
              </button>
              <div className="flex justify-center flex-col items-center  mt-6 px-2">
                <img
                  src={`${require("../assets/icons/question-mark.svg")}`}
                  alt="question mark in circle"
                />
                <span className="mt-4  text-xl text-center">
                  {t("fieldListPage.deleteFieldModalContent")}
                </span>
              </div>
              <div className="mt-8 flex justify-between">
                <button
                  className="rounded-md border border-transparent bg-gray-200 py-2 px-4 text-sm font-medium shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
                  onClick={close}
                >
                  {t("fieldListPage.closeModal")}
                </button>

                <button
                  className="rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 disabled:opacity-50"
                  onClick={(e) => deleteFieldOnClick(e)}
                >
                  {t("fieldListPage.deleteField")}
                </button>
              </div>
            </div>
          );
        }
      }
    </Popup>
  );
};

export default AlertModalField;
