import React, { FC } from "react";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";

import { Sonda, deleteSonda } from "../store/sonda/sondaSlice";
import SondaWithCode from "./SondaWithCode";
import { useAppDispatch } from "../store";
import AlertModal from "./AlertModal";

interface SondaProps {
    sonda: Sonda;
    index: number;
    fieldId: number;
}

const SondaListItem: FC<SondaProps> = ({ sonda, index, fieldId }) => {
    const { t } = useTranslation("translation");

    const dispatch = useAppDispatch();

    const deleteSondaOnClick = (event) => {
        event.preventDefault();
        dispatch(deleteSonda(sonda.sondaCode, fieldId));
    };

    return (
        <tr key={sonda.sondaCode}>
            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 md:pl-0">
                <SondaWithCode index={index} sondaCode={sonda.sondaCode} />
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm">
                <span className="text-gray-500">{t("fieldListPage.battery")} </span>
                <span className="font-bold">{`${sonda.measurements?.[0]?.battery}%`}</span>
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm">
                <span className="text-gray-500">{t("fieldListPage.connection")} </span>
                <span className="font-bold">{`${sonda.measurements?.[0]?.connection}%`}</span>
            </td>

            <td className="flex whitespace-nowrap py-4 px-3 text-sm">
                {sonda.measurements?.[0]?.error_status && sonda.measurements[0].error_status.length > 0 && (
                    <Popup
                        trigger={
                            <button>
                                <img
                                    src={`${require("../assets/icons/error.svg")}`}
                                    alt="flag"
                                />
                            </button>
                        }
                        position="top center"
                    >
                        <div className="bg-amber-200 rounded-lg px-2 py-1">
                            <ul className="text-xs">
                                {sonda.measurements[0].error_status
                                    ?.filter((error) => error != null && error != undefined)
                                    .map((error, index) => (
                                        <li className="py-1" key={index}>
                                            {t(`sondaErrors.${error}`, { defaultValue: t("common.generalError") })}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </Popup>
                )}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm">
                <AlertModal deleteSondaOnClick={deleteSondaOnClick} />
            </td>
        </tr>
    );
};

export default SondaListItem;
