import React, { FC } from "react";

import { Sonda } from "../store/sonda/sondaSlice";
import Pill from "./Pill";
import SondaWithCode from "./SondaWithCode";

interface MeasureListItemProps {
  sonda: Sonda;
  value: any;
  index: number;
  formattedDate: string;
  unit?: string;
}

function isValidValue(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
}

const MeasurementListItem: FC<MeasureListItemProps> = ({
  sonda,
  value,
  index,
  unit,
  formattedDate,
}) => {
  return (
    <tr key={sonda.sondaCode}>
      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 md:pl-0">
        <SondaWithCode index={index} sondaCode={sonda.sondaCode} />
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm">
        <Pill>{isValidValue(value) ? `${value} ${unit}` : "No Data"}</Pill>
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm text-right text-gray-500">
        {formattedDate}
      </td>
    </tr>
  );
};

export default MeasurementListItem;
