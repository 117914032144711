import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const Footer: FC = () => {
  const { t } = useTranslation("translation");

  return (
    <footer className="text-center p-8">
      <div className="text-sm text-gray-700 text-center">
        {t("common.footer")}
      </div>
    </footer>
  );
};

export default Footer;
