import { LatLngExpression } from "leaflet";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import ActionPanel from "../components/ActionPanel";
import Map from "../components/Map";
import { useAppDispatch } from "../store";
import {
  Field,
  saveField,
  selectNewField,
  selectSaveFieldSuccess,
} from "../store/fields/fieldsSlice";

const AddField: FC = () => {
  const initialPosition: LatLngExpression = {
    lat: 52.5170365,
    lng: 13.3888599,
  }; // Berlin
  const initialZoom: number = 10;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveFieldSuccess: boolean = useSelector(selectSaveFieldSuccess);
  const newField: Field | undefined = useSelector(selectNewField);

  const [lat, setLat] = useState(initialPosition.lat);
  const [lng, setLng] = useState(initialPosition.lng);
  const [name, setName] = useState("");

  const { t } = useTranslation("translation");

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(saveField(name, lat, lng));
  };

  const handleResultSelect = (result) => {
    setLat(result.location.y);
    setLng(result.location.x);
  };

  useEffect(() => {
    if (saveFieldSuccess) {
      navigate("/new-sonda", {
        state: { field: newField },
      });
    }
  }, [saveFieldSuccess]);

  return (
    <ActionPanel>
      <h1 className="text-3xl font-bold tracking-tight text-gray-900">
        {t("addFieldPage.addFieldTitle")}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-500">
            {t("addFieldPage.fieldName")}
          </label>
          <div className="mt-2 md:w-2/5">
            <input
              id="name"
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm"
              required
            />
          </div>
        </div>
        <div className="my-8">
          <Map
            center={initialPosition}
            zoom={initialZoom}
            onResultSelect={handleResultSelect}
            searchOn={true}
            infoOn={false}
            editOn={false}
          />
        </div>
        <div className="mt-5 flex justify-between">
          <Link
            to="/"
            className="rounded-md border border-transparent bg-gray-200 py-2 px-4 text-sm font-medium shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
          >
            {t("addFieldPage.cancelButton")}
          </Link>
          <button
            type="submit"
            className="rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
          >
            {t("addFieldPage.saveButton")}
          </button>
        </div>
      </form>
    </ActionPanel>
  );
};

export default AddField;
