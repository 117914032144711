import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import FieldListItem from "../components/FieldListItem";
import { useAppDispatch } from "../store";
import { Field, fetchFields } from "../store/fields/fieldsSlice";
import {
  clearAssignSondaSuccess,
  clearDeleteSondaSuccess,
  selectDeleteSondaSuccess,
} from "../store/sonda/sondaSlice";

interface FieldListProps {
  fields: Field[];
}

const FieldList: FC<FieldListProps> = ({ fields }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation");

  useEffect(() => {
    dispatch(clearAssignSondaSuccess());
    dispatch(clearDeleteSondaSuccess());
  }, []);

  return (
    <>
      <div className="sm:mx-auto sm:w-full mt-4 mx-auto max-w-6xl">
        <div className="flex items-center px-4 mt-8">
          <h1 className="text-3xl	font-extrabold mr-8">
            {" "}
            {t("fieldListPage.allFields")}{" "}
          </h1>
          <Link
            to="/add-field"
            className="flex float-right min-w-40 max-w-60 justify-center items-center rounded-md border border-transparent bg-amber-300 py-[4px] px-6 text-lg font-bold shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
          >
            {t("fieldListPage.addFieldButton")}{" "}
            <span className="ml-2 text-2xl font-light">+</span>
          </Link>
        </div>
      </div>
      <div className="clear-right">
        {fields.map((field: Field, index) => {
          return <FieldListItem key={index} field={field} />;
        })}
      </div>
    </>
  );
};

export default FieldList;
