import { useEffect, useState } from "react";
import {
  ApiType,
  BBox,
  CRS_EPSG4326,
  MimeTypes,
  S2L2ALayer,
} from "@sentinel-hub/sentinelhub-js";

import { LatLngBounds, latLngBounds } from "leaflet";
import { scripts } from "../utils/scriptsData";
import authorize from "../auth/sentinelHubAuth";
import { CoordinatesBbox } from "../types/coordinatesBbox";

interface useSatelliteImagery {
  selectedScriptName: string;
  coordinatesLatLngBounds: any;
  dates: string;
  infoActive: boolean;
  imageUrl: string | ArrayBuffer;
  errorMessage: string | null;
  loading: boolean;
  setSelectedScriptName: any;
  handleAreaCreated: (event: {
    layer: { getLatLngs: () => Array<{ lat: number; lng: number }>[] };
  }) => void;
  clearImageryData: () => void;
}

const useSatalliteImagery = (): useSatelliteImagery => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedScriptName, setSelectedScriptName] = useState("moistureIndex"); // default script name
  const [coordinatesBbox, setCoordinatesBbox] =
    useState<CoordinatesBbox | null>(null);
  const [coordinatesLatLngBounds, setCoordinatesLatLngBounds] =
    useState<LatLngBounds>();
  const [infoActive, setInfoActive] = useState(false);
  const [dates, setDates] = useState("");
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer>("");

  const clearImageryData = () => {
    setImageUrl("");
    setInfoActive(false);
  };

  const handleAreaCreated = (event: {
    layer: { getLatLngs: () => Array<{ lat: number; lng: number }>[] };
  }) => {
    // Resetting the state manually for new menu
    setSelectedScriptName("moistureIndex");
    const { layer } = event;

    const latLngs = layer.getLatLngs();
    const coordinates = latLngs[0];

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    for (let i = 0; i < coordinates.length; i++) {
      const lat = coordinates[i].lat;
      const lng = coordinates[i].lng;

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }

    const bbox = {
      minLng: minLng,
      minLat: minLat,
      maxLng: maxLng,
      maxLat: maxLat,
    };

    setCoordinatesBbox(bbox);

    // Create latLngBounds using the southwest and northeast points of the bbox
    const cooridatatesBounds = latLngBounds([minLat, minLng], [maxLat, maxLng]);
    setCoordinatesLatLngBounds(cooridatatesBounds);
    setLoading(true);
    fetchImageUrl(bbox, scripts[selectedScriptName]);
  };

  // image conversion
  const blobToDataURI = (
    blob: Blob
  ): Promise<string | ArrayBuffer | null | undefined> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.onerror = (error) => {
        reject(new Error("Failed to convert blob to data URI: "));
        setErrorMessage("Failed to convert blob to data URI");
      };
      reader.readAsDataURL(blob);
    });
  };

  const fetchImageUrl = async (
    bbox: { minLng: number; minLat: number; maxLng: number; maxLat: number },
    script: string
  ): Promise<void> => {
    setErrorMessage(null);
    const evalscript = script;

    const layer = new S2L2ALayer({
      evalscript: evalscript,
      maxCloudCoverPercent: 80,
      // @sentinel-hub/sentinelhub-js is missing upsampling type, so we have to ignore type error
      // it inherits it from AbstractSentinelHubV3Layer, however the type is not inherited
      // this might get fixed in the future updates of the library
      // @ts-ignore
      upsampling: "BICUBIC",
    });

    const currentDate = new Date();
    const toTime = new Date(
      `${currentDate.toISOString().split("T")[0]}T00:00:00Z`
    );

    const getMapParams = {
      bbox: new BBox(
        CRS_EPSG4326,
        bbox.minLng,
        bbox.minLat,
        bbox.maxLng,
        bbox.maxLat
      ),
      fromTime: new Date("2023-08-05T00:00:00Z"),
      toTime: toTime,
      width: 512,
      height: 343.697,
      format: MimeTypes.JPEG,
    };

    try {
      const responseBlob = await layer.getMap(getMapParams, ApiType.PROCESSING);
      const dates = await layer.findDatesUTC(
        getMapParams.bbox,
        getMapParams.fromTime,
        getMapParams.toTime
      );

      // getting the last date
      const limitedDates = dates.slice(0, 1);
      const dateStr = limitedDates[0].toISOString();
      setDates(dateStr);
      setLoading(false);
      setInfoActive(true);

      if (responseBlob instanceof Blob && responseBlob.type === "image/jpeg") {
        const dataURI = await blobToDataURI(responseBlob);
        dataURI && setImageUrl(dataURI);
      } else {
        console.warn("Unexpected response format:", responseBlob);
        setErrorMessage("Unexpected response format. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      setErrorMessage(
        "There was a problem fetching the image. Please try again later."
      );
    }
  };

  useEffect(() => {
    authorize();
  }, []);

  useEffect(() => {
    if (selectedScriptName && coordinatesBbox) {
      fetchImageUrl(coordinatesBbox, scripts[selectedScriptName]);
    }
  }, [selectedScriptName]);

  return {
    setSelectedScriptName,
    handleAreaCreated,
    clearImageryData,
    selectedScriptName,
    coordinatesLatLngBounds,
    loading,
    errorMessage,
    infoActive,
    imageUrl,
    dates,
  };
};

export { useSatalliteImagery };
