import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../";
import { axiosClient } from "../../api";
import i18n from "../../i18n";
import {
  NotificationType,
  triggerNotification,
} from "../notification/notificationSlice";
import {fetchFields, Field} from "../fields/fieldsSlice";

export type Measurement = {
  moisture: string;
  temperature: string;
  nitrate: string;
  nitrateLow: number;
  nitrateHigh: number;
  nitrateAvg: number;
  phosphate: string;
  ph: string;
  battery: string;
  connection: string;
  latitude: number;
  longitude: number;
  dev_add: string;
  time: string;
  error_status: string[];
  __dt: string;
  date_time: string;
};

export type LastValidMeasurement = {
  moisture: [number, number] | [null, null];
  soilTemperature: [number, number] | [null, null];
  nitrateAvg: [number, number] | [null, null];
  phosphate: [null, null];
  ec: [number, number] | [null, null];
  ph: [null, null];
  errStatus: [number, number] | [null, null];
};

export type Sonda = {
  sondaCode: string;
  lat: number;
  lng: number;
  measurements?: Measurement[] | LastValidMeasurement;
  latest_valid?: any;
};

interface SondaState {
  assignSondaSuccess: boolean;
  checkSondaSuccess: boolean;
  deleteSondaSuccess: boolean;
  sonda?: Sonda;
}

const initialSondaState: SondaState = {
  assignSondaSuccess: false,
  checkSondaSuccess: false,
  deleteSondaSuccess: false,
};

const sondaSlice = createSlice({
  name: "sonda",
  initialState: initialSondaState,
  reducers: {
    assignSondaSuccess: (state) => {
      state.assignSondaSuccess = true;
    },
    clearAssignSondaSuccess: (state) => {
      state.assignSondaSuccess = false;
    },
    clearCheckSondaSuccess: (state) => {
      state.checkSondaSuccess = false;
    },
    doCheckSonda: (state, action: PayloadAction<Sonda>) => {
      state.checkSondaSuccess = true;
      state.sonda = action.payload;
    },
    deleteSondaSuccess: (state) => {
      state.deleteSondaSuccess = true;
    },
    clearDeleteSondaSuccess: (state) => {
      state.deleteSondaSuccess = false;
    },
  },
});

export const {
  assignSondaSuccess,
  clearAssignSondaSuccess,
  clearCheckSondaSuccess,
  doCheckSonda,
  deleteSondaSuccess,
  clearDeleteSondaSuccess,
} = sondaSlice.actions;

export const sondaReducer = sondaSlice.reducer;

export const selectAssignSondaSuccess = (state: RootState): boolean =>
  state.sonda.assignSondaSuccess;
export const selectCheckSondaSuccess = (state: RootState): boolean =>
  state.sonda.checkSondaSuccess;
export const selectCheckedSonda = (state: RootState): Sonda | undefined =>
  state.sonda.sonda;
export const selectDeleteSondaSuccess = (state: RootState): boolean =>
  state.sonda.deleteSondaSuccess;
export const selectDeleteSonda = (state: RootState): Sonda | undefined =>
  state.sonda.sonda;

export const checkSonda = (sondaCode: string, field: Field) => async (dispatch) => {
  try {
    await axiosClient
      .post(`/api/v1/sondas/${sondaCode}/check`)
      .then((response) => {
        if(!response.data.lat || !response.data.lng) {
          response.data.lat = field.lat;
          response.data.lng = field.lng;
        }
        dispatch(doCheckSonda(response.data));
      });
  } catch (e) {
    dispatch(
      triggerNotification({
        messages: ["common.errorMessage"],
        type: NotificationType.ERROR,
      })
    );
  }
};

export const assignSonda =
  (fieldId: number, sondaCode: string | undefined) => async (dispatch) => {
    try {
      await axiosClient
        .post(`/api/v1/fields/${fieldId}/assign_sonda`, {
          sondaCode,
        })
        .then((response) => {
          dispatch(assignSondaSuccess());
          dispatch(
            triggerNotification({
              messages: ["fieldListPage.saveSondaSuccessAlert"],
              type: NotificationType.SUCCESS,
            })
          );
        });
    } catch (e) {
      dispatch(
        triggerNotification({
          messages: ["common.errorMessage"],
          type: NotificationType.ERROR,
        })
      );
    }
  };

export const deleteSonda =
  (sondaCode: string, fieldId: number) => async (dispatch) => {
    try {
      await axiosClient
        .delete(`/api/v1/sondas/${sondaCode}/${fieldId}`)
        .then((response) => {
          dispatch(deleteSondaSuccess());
          dispatch(fetchFields());
          dispatch(
            triggerNotification({
              messages: ["fieldListPage.deleteSondaSuccessAlert"],
              type: NotificationType.SUCCESS,
            })
          );
        });
    } catch (e) {
      dispatch(
        triggerNotification({
          messages: ["common.errorMessage"],
          type: NotificationType.ERROR,
        })
      );
    }
  };
