import React, { FC } from "react";

interface InputFieldProps {
  id: string;
  name: string;
  onChangeHandler: (e) => void;
}

const InputField: FC<InputFieldProps> = ({ id, name, onChangeHandler }) => {
  return (
    <input
      className="w-52 sm:w-60 h-16 sm:m-1 appearance-none rounded-md border border-gray-300 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 text-lg text-center p-0"
      id={id}
      maxLength={16}
      name={name}
      onChange={onChangeHandler}
      required
      type="text"
    ></input>
  );
};

export default InputField;
