import { LeafletEventHandlerFn } from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { FC, useEffect } from "react";
import { useMap } from "react-leaflet";

interface MapSearchProps {
  onResultSelect: LeafletEventHandlerFn;
}

const MapSearch: FC<MapSearchProps> = ({ onResultSelect }) => {
  const map = useMap();
  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    // @ts-ignore
    const searchControl = new GeoSearchControl({
      autoClose: true,
      keepResult: true,
      notFoundMessage: "Sorry, that address could not be found.",
      provider: provider,
      style: "bar",
    });

    map.addControl(searchControl);
    map.on("geosearch/showlocation", onResultSelect);

    return () => {
      map.removeControl(searchControl);
    };
  }, []);

  return null;
};

export default MapSearch;
