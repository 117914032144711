type AuthStoredData = {
  uid: string;
  client: string;
  accessToken: string;
  expiry: string;
};

interface AuthState {
  isAuthenticated: boolean;
  error?: string;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
};

export { AuthStoredData, AuthState, initialAuthState };
