import React, { FC } from "react";
import SondaListItem from "../components/SondaListItem";
import { Sonda } from "../store/sonda/sondaSlice";
import Table from "../components/Table";

interface SondaListProps {
  sondas: Sonda[];
  fieldId: number;
}

const SondaList: FC<SondaListProps> = ({ sondas, fieldId }) => {
  return (
    <Table>
      {sondas.map((sonda: Sonda, index: number) => {
        return <SondaListItem sonda={sonda} index={index} fieldId={fieldId} key={index}/>;
      })}
    </Table>
  );
};

export default SondaList;
