import React, { FC } from "react";

interface TableProps {
  children: React.ReactNode;
}

const Table: FC<TableProps> = ({ children }) => {
  return (
    <div className="flex flex-col">
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200">
              <>{children}</>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
