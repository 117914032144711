import React, { FC } from "react";

interface EditablePillProps {
  children: React.ReactNode;
}

const EditablePill: FC<EditablePillProps> = ({ children }) => (
  <div className="rounded-full bg-gray-100 px-2 py-1 text-center leading-6 inline-block cursor-pointer border-2 hover:border-amber-300">
    {children}
  </div>
);

export default EditablePill;
