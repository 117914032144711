import { sentinelHubApiClient } from "../api";
import { setAuthToken } from "@sentinel-hub/sentinelhub-js";

const client_secret = process.env.SENTINEL_HUB_API_CLIENT_SECRET;
const client_id = process.env.SENTINEL_HUB_API_CLIENT_ID;

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
};

const body = {
  client_id,
  client_secret,
  grant_type: "client_credentials",
};

const authorize = async () => {
  try {
    const response = await sentinelHubApiClient.post(
      "/oauth/token",
      body,
      config
    );
    setAuthToken(response.data.access_token);
  } catch (error) {
    console.error("Error fetching the token:", error);
  }
};

export default authorize;
