import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import LoadingSpinner from "../components/LoadingSpinner";
import FieldList from "../pages/FieldList";
import { useAppDispatch } from "../store";
import {
  fetchFields,
  Field,
  selectFields,
  selectIsLoading,
} from "../store/fields/fieldsSlice";
import StartMonitoring from "./StartMonitoring";

const renderDashboard = (fields: Field[]) => {
  if (fields.length === 0) {
    return <StartMonitoring />;
  } else {
    return <FieldList fields={fields} />;
  }
};

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const fields: Field[] | undefined = useSelector(selectFields);
  const isLoading: boolean = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchFields());
  }, []);

  return isLoading ? <LoadingSpinner /> : renderDashboard(fields);
};

export default Dashboard;
