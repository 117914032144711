import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import AppRouter from "./AppRouter";
import AuthProvider from "./auth/provider";
import store from "./store";

const App = () => {
  return (
    <StrictMode>
      <AuthProvider>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </AuthProvider>
    </StrictMode>
  );
};

export default App;
