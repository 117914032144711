import { LatLngExpression } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ActionPanel from "../components/ActionPanel";
import Map from "../components/Map";
import { useAppDispatch } from "../store";
import {
  assignSonda,
  selectAssignSondaSuccess,
  selectCheckedSonda,
  Sonda,
} from "../store/sonda/sondaSlice";

const AddSonda: FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const assignSondaSuccess: boolean = useSelector(selectAssignSondaSuccess);
  const checkedSonda: Sonda | undefined = useSelector(selectCheckedSonda);
  const sondas: Sonda[] = checkedSonda ? [checkedSonda] : [];

  const { t } = useTranslation("translation");
  const initialPosition: LatLngExpression = {
    lat: checkedSonda?.lat || 0,
    lng: checkedSonda?.lng || 0,
  };

  const sondaError: boolean =
    checkedSonda?.lat == undefined && checkedSonda?.lng == undefined;
  const initialZoom: number = 15;

  const handleClick = (event) => {
    event.preventDefault();
    dispatch(assignSonda(state.field.fieldId, checkedSonda?.sondaCode));
  };

  useEffect(() => {
    if (state == undefined) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (assignSondaSuccess) {
      navigate("/");
    }
  }, [assignSondaSuccess]);

  const renderSondaMarkers = (sondas: Sonda[] | undefined): React.ReactNode => {
    if (sondas !== undefined) {
      return sondas.map((sonda: Sonda) => {
        const lastMeasurementLat = sonda.lat;
        const lastMeasurementLng = sonda.lng;

        // Hotfix for NaN  sonda coordinates
        // Check for NaN and set to 0 if true
        const latitude = isNaN(lastMeasurementLat) ? 0 : lastMeasurementLat;
        const longitude = isNaN(lastMeasurementLng) ? 0 : lastMeasurementLng;

        const sondaPosition: LatLngExpression = {
          lat: latitude,
          lng: longitude,
        };

        return (
          <Marker key={sonda.sondaCode} position={sondaPosition}>
            <Popup>{`${t("common.sonda")} ${sonda.sondaCode}`}</Popup>
          </Marker>
        );
      });
    }
  };

  return (
    <ActionPanel>
      <h1 className="text-3xl font-bold tracking-tight text-gray-900">
        {t("addSondaPage.addSondaTitle")}
      </h1>
      <div className="mt-5">
        <label className="block text-2xl font-medium text-gray-500 font-medium">
          {state.field.name}
        </label>
      </div>
      {sondaError ? (
        <div className="mb-10 mt-10 text-sm text-gray-400">
          <p>{t("addSondaPage.sondaError")}</p>
        </div>
      ) : (
        <div className="my-8">
          <Map
            center={initialPosition}
            zoom={initialZoom}
            infoOn={false}
            editOn={false}
          >
            {renderSondaMarkers(sondas)}
          </Map>
        </div>
      )}
      <div className="mt-5 flex justify-between">
        <Link
          to="/"
          className="rounded-md border border-transparent bg-gray-200 py-2 px-4 text-sm font-medium shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
        >
          {t("addSondaPage.cancelButton")}
        </Link>
        <button
          onClick={(e) => handleClick(e)}
          disabled={sondaError}
          type="button"
          className="rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 disabled:opacity-50"
        >
          {t("addSondaPage.assignSondaButton")}
        </button>
      </div>
    </ActionPanel>
  );
};

export default AddSonda;
