import { combineReducers } from "@reduxjs/toolkit";

import { fieldsReducer } from "./fields/fieldsSlice";
import { notificationReducer } from "./notification/notificationSlice";
import { sondaReducer } from "./sonda/sondaSlice";

const rootReducer = combineReducers({
  fields: fieldsReducer,
  notification: notificationReducer,
  sonda: sondaReducer,
});

export default rootReducer;
