import React, { useEffect, useMemo, useReducer } from "react";

import { axiosClient } from "../api";
import { NullableProperties } from "../types/utilityTypes";
import AuthContext from "./context";
import { authReducer, doSignIn, doSignOut, setError } from "./reducer";
import { AuthStoredData, initialAuthState } from "./state";

const AuthProvider = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  const setAuthDataInStorage = ({
    uid,
    client,
    accessToken,
    expiry,
  }: AuthStoredData): void => {
    localStorage.setItem("uid", uid);
    localStorage.setItem("client", client);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("expiry", expiry);
  };

  const retrieveAuthDataFromStorage =
    (): NullableProperties<AuthStoredData> => ({
      uid: localStorage.getItem("uid"),
      client: localStorage.getItem("client"),
      accessToken: localStorage.getItem("accessToken"),
      expiry: localStorage.getItem("expiry"),
    });

  const deleteAuthDataFromStorage = (): void => {
    localStorage.removeItem("uid");
    localStorage.removeItem("client");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiry");
  };

  const setAuthHeader = (auth: AuthStoredData): void => {
    axiosClient.defaults.headers.common["content-type"] = "application/json";
    axiosClient.defaults.headers.common["uid"] = auth.uid;
    axiosClient.defaults.headers.common["client"] = auth.client;
    axiosClient.defaults.headers.common["access-token"] = auth.accessToken;
    axiosClient.defaults.headers.common["expiry"] = auth.expiry;
  };

  const deleteAuthHeader = () => {
    delete axiosClient.defaults.headers.common["uid"];
    delete axiosClient.defaults.headers.common["client"];
    delete axiosClient.defaults.headers.common["access-token"];
    delete axiosClient.defaults.headers.common["expiry"];
  };

  const signin = async (email: string, password: string) => {
    const requestBody = { email, password };

    try {
      await axiosClient.post("auth/sign_in", requestBody).then((response) => {
        const uid = response.headers["uid"] || "";
        const client = response.headers["client"] || "";
        const accessToken = response.headers["access-token"] || "";
        const expiry = response.headers["expiry"] || "";

        dispatch(doSignIn());
        setAuthHeader({ uid, client, accessToken, expiry });
        setAuthDataInStorage({
          uid: uid,
          client: client,
          accessToken: accessToken,
          expiry: expiry,
        });
      });
    } catch (e) {
      dispatch(setError({ error: e.message })); // TODO the message from be is not translated, therefore it's not used now
    }
  };

  const signout = async () => {
    try {
      await axiosClient.delete("auth/sign_out");
    } catch (e) {
      dispatch(setError({ error: e.message })); // TODO the message from be is not translated, therefore it's not used now
    } finally {
      dispatch(doSignOut());
      deleteAuthHeader();
      deleteAuthDataFromStorage();
    }
  };

  useEffect(() => {
    const { uid, client, accessToken, expiry } = retrieveAuthDataFromStorage();

    if (uid && client && accessToken && expiry) {
      dispatch(doSignIn());
      setAuthHeader({ uid, client, accessToken, expiry });
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      ...state,
      signin,
      signout,
    };
  }, [state]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
