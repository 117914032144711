import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

import { useAuth } from "../auth/useAuth";
import LanguageButton from "./LanguageButton";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBar: FC = () => {
  const { isAuthenticated, signout } = useAuth();
  const { t } = useTranslation("translation");

  const handleSignOut = async (event) => {
    event.preventDefault();
    await signout();
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      src={`${require("../assets/Nerite-Wordmark-BT.png")}`}
                      alt="Nerit'e"
                      className="h-5"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <Link
                    to="/"
                    className="inline-flex items-center border-b-2 border-amber-300 px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    {t("navBar.myFields")}
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <LanguageButton />
                {/* My Account dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="inline-flex items-center rounded-md border-1 border px-2 py-1 text-sm font-medium text-gray-900">
                      {t("navBar.myAccount")}
                      <ChevronDownIcon
                        className="block h-4 w-4 ml-2"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://nerite.tech/about-us"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            target="_blank"
                          >
                            {t("navBar.about")}
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://nerite.tech/terms-of-service"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            target="_blank"
                          >
                            {t("navBar.termsOfService")}
                          </a>
                        )}
                      </Menu.Item>
                      {isAuthenticated && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={handleSignOut}
                            >
                              {t("navBar.logOut")}
                            </a>
                          )}
                        </Menu.Item>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <LanguageButton />
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-300">
                  <span className="sr-only">{t("navBar.openMainMenu")}</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-3">
              <Disclosure.Button
                as="a"
                href="/"
                className="block border-l-4 border-amber-300 bg-gray-50 py-2 pl-3 pr-4 text-base font-medium text-black"
              >
                {t("navBar.myFields")}
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="https://nerite.tech/about-us"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  target="_blank"
                >
                  {t("navBar.about")}
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="https://nerite.tech/terms-of-service"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  target="_blank"
                >
                  {t("navBar.termsOfService")}
                </Disclosure.Button>
                {isAuthenticated && (
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    onClick={handleSignOut}
                  >
                    {t("navBar.logOut")}
                  </Disclosure.Button>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
