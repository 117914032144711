import React, { FC } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type MeasurementType = {
  name: string;
  id: string;
  unit: string;
};

interface MeasurementTypeTabsProps {
  tabs: MeasurementType[];
  handleTabChange: (tabId: string) => void;
  activeTab: string;
  hasMeasurementTypes: boolean;
}

const MeasurementTypeTabs: FC<MeasurementTypeTabsProps> = ({
  tabs,
  handleTabChange,
  activeTab,
  hasMeasurementTypes,
}) => {
  const { t } = useTranslation("translation");

  const handleChange = (event) => {
    handleTabChange(event.target.id);
  };

  const handleChangeMobile = () => {
    const e = document.getElementById("tabs");
    handleTabChange(e.value);
  };

  return (
    <div>
      <div className="sm:hidden mt-4">
        <label htmlFor="tabs" className="sr-only">
          {t("fieldDetailsPage.selectMeasurementType")}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs[0].name}
          onChange={handleChangeMobile}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-5" aria-label="Tabs">
          {tabs.map((tab) => {
            const isActive: boolean = tab.id === activeTab && hasMeasurementTypes;
            return (
              <button
                id={tab.id}
                key={tab.name}
                className={classNames(
                  isActive ? "bg-amber-300" : "text-gray-800 hover:text-black cursor-auto",
                  "px-3 py-2 font-medium text-sm rounded-md"
                )}
                aria-current={isActive ? "page" : undefined}
                onClick={handleChange}
              >
                {tab.name}
              </button>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default MeasurementTypeTabs;
